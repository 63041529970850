import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';

const flatten = (obj) => {
  const separator = '.';
  const iterator = (source, prefix, flattened) => {
    const keys = Object.keys(source);

    for (let n = 0; n < keys.length; n += 1) {
      const key = keys[n];
      const val = source[key];

      if (!_isObject(val)) {
        // eslint-disable-next-line no-param-reassign
        flattened[prefix + key] = val;
      } else {
        iterator(val, prefix + key + separator, flattened);
      }
    }
  };

  const result = {};
  iterator(obj, '', result);
  return result;
};

const format = (string, data) =>
  string.replace(/\{[0-9a-zA-Z_\\.\\-]+\}/g, (match, i) => {
    console.log('match:', match, 'atIndex:', i, 'data:', data);
    const key = match.substring(1, match.length - 1);
    console.log('key:', key);
    return data[key] || 'undefined';
  });

/**
 * Function to parse the UI event into the rasa format message
 * @param {DomEvent} event
 * @param {object} values
 * {
      template,
      attributes,
      action:{
        id: 'action_1',
        value: 'action_1',
        type: 'primary',
        text: 'ACCEPT',
        display: 'optional'
      }
    }
  @returns {object}
  {
    messageAttributes {
      annotation: /intentName
    },
    message: {
      type: 'simple',
      text: message,
    }
  }
 */
const handleActions = (event, values) => {
  console.log('handleActions:', values);
  const { template, attributes, action } = values || {};
  const message = template
    ? format(template, {
        ...attributes.slots,
      })
    : action.text;
  const intentName = action.id || '';
  const annotationStr = `/${intentName}`;
  return {
    messageAttributes: {
      annotation: annotationStr,
    },
    message: {
      type: 'simple',
      text: message,
    },
  };
};

/**
 * Function to parse the UI event into the rasa format message
 * @param {DomEvent} event
 * @param {object} values
 * {
      template,
      attributes,
      action,
      fields: {
        ...values,
      }
    }
  @returns {object}
  {
    messageAttributes {
      annotation: /intentName{"entityName1":{"entityValue1":49.76, "entityValue2": 2}, "entityName2":{"entityValue1":49.76, "entityValue2": 2}}
    },
    message: {
      type: 'simple',
      text: message,
    }
  }
 */
const handleForm = (event, values) => {
  console.log(values);
  const { template, attributes, action, fields } = values || {};
  const message = template
    ? format(values.template, {
        ...attributes.slots,
        ...flatten(fields),
      })
    : action.text;
  const intentName = action.id || '';
  const entityValues = _isEmpty(fields) ? '' : JSON.stringify(fields);
  const annotationStr = `/${intentName}${entityValues}`;
  return {
    messageAttributes: {
      annotation: annotationStr,
    },
    message: {
      type: 'simple',
      text: message,
    },
  };
};

/**
 *
 * @param {DomEvent} event
 * @param {object} values {
 *  text: 'string'
 * }
 */
const handleInput = (event, values) => {
  const { text } = values;
  return {
    messageAttributes: {},
    message: {
      type: 'simple',
      text,
    },
  };
};

/**
 *
 * @param {object} values
 * {
      intentName,
      fields: {
        ...values,
      },
      text: 'Product enquiry'
    }
  @returns {object}
  {
    messageAttributes {
      annotation: /request_nego{"product_id": "DR10005060", "organisation_id": "angliss", "customer_name": "test", "customer_email":"test@test.com"}
    },
    message: {
      type: 'simple',
      text: message,
    }
  }
 */
const handleDefault = (event, values) => {
  const { intentName, fields, text } = values;
  const entityValues = _isEmpty(fields) ? '' : JSON.stringify(fields);
  const annotationStr = `/${intentName}${entityValues}`;
  return {
    messageAttributes: {
      annotation: annotationStr,
    },
    message: {
      type: 'simple',
      text,
    },
  };
};

/**
 * Function to parse the UI event into the rasa format message
 * @param {DomEvent} event
 * @param {object} values
 * {
      template,
      attributes,
      action:{
        id: 'action_1',
        value: 'action_1',
        type: 'primary',
        text: 'ACCEPT',
        display: 'optional'
      }
    }
  @returns {object}
  {
    messageAttributes {
      annotation: /intentName
    },
    message: {
      type: 'simple',
      text: message,
    }
  }
 */
const handleCarousel = (event, values) => {
  console.log('handleCarousel:', values);
  const { template, attributes, action } = values || {};
  const message = template
    ? format(values.template, {
        ...attributes.slots,
      })
    : action.text;
  const intentName = action.id || '';
  const entityValues = _isEmpty(action.value) ? '' : JSON.stringify(action.value);
  const annotationStr = `/${intentName}${entityValues}`;
  return {
    messageAttributes: {
      annotation: annotationStr,
    },
    message: {
      type: 'simple',
      text: message,
    },
  };
};

export { handleActions, handleForm, handleInput, handleDefault, handleCarousel };
