/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Card, Button } from 'antd';
import MarkdownText from './components/markdown-text';

/**
 * MessageSimple - Render component, should be used together with the Message component
 *
 */
const MessageCarousel = ({ text, list, actionHandler, attributes, template }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };
  return (
    <div>
      <MarkdownText text={text} />
      <Slider {...settings} style={{ margin: '10px 20px 20px 20px' }}>
        {list.map((data) => {
          const { type, values } = data;
          const { image, title, description, actions } = values;
          let slideComp = null;
          switch (type) {
            case 'Card':
              slideComp = (
                <Card
                  style={{ margin: '0px 5px' }}
                  cover={<img alt={title} src={image} style={{ height: '100px' }} />}
                  actions={
                    actions &&
                    actions.map((action) => {
                      return (
                        <Button
                          id={action.id}
                          type={action.type}
                          block
                          onClick={(event) => {
                            actionHandler({
                              type: 'MessageCarousel',
                              event,
                              values: {
                                template,
                                attributes: {
                                  slots: {
                                    ...attributes.slots,
                                    ...action.value,
                                  },
                                },
                                action,
                              },
                            });
                          }}
                        >
                          {action.text}
                        </Button>
                      );
                    })
                  }
                >
                  <Card.Meta title={title} description={description} />
                </Card>
              );
              break;
            default:
              slideComp = <div>Unsupported Message Format</div>;
              break;
          }
          return <div>{slideComp}</div>;
        })}
      </Slider>
    </div>
  );
};

MessageCarousel.propTypes = {
  /** The Markdown or html source to parse */
  text: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.object,
    }),
  ).isRequired,
  /** Handler for actions */
  actionHandler: PropTypes.func.isRequired,
  /** Message template */
  template: PropTypes.string.isRequired,
  /** Attributes */
  attributes: PropTypes.object.isRequired,
};

export default MessageCarousel;
