import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';

/**
 * ActionBar - The actions you can take on an message
 */
const ActionsBar = ({ actions, actionHandler }) => {
  /**
   *
   * @param {*} event
   * @param {*} action
   */
  const onClickHandler = (event, action) => {
    actionHandler({
      type: 'MessageActions',
      event,
      values: {
        [action.id]: {
          ...action,
        },
      },
    });
  };

  return (
    <Row gutter={16} type="flex" justify="center">
      {actions.map((action) => (
        <Col>
          <Button
            id={action.id}
            type={action.type}
            onClick={(event) => onClickHandler(event, action)}
          >
            {action.text}
          </Button>
        </Col>
      ))}
    </Row>
  );
};

ActionsBar.propTypes = {
  /**
   * A list of actions
   * */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      type: PropTypes.oneOf(['default', 'primary', 'ghost', 'dashed', 'danger', 'link']),
      text: PropTypes.string,
    }),
  ).isRequired,
  /**
   * Handler for the action that pass the object {type, event, value} as the param
   * @param {string} type
   * @param {DomEvent} event
   * @param {object} value
   */
  actionHandler: PropTypes.func.isRequired,
};

export default ActionsBar;
