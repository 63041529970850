import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InfiniteLoader } from 'react-virtualized';
import VAutoSizer from './v-autosizer';

const VInfiniteLoader = ({ loadMore, rowCount, rowHeight, rowRenderer, scrollTo }) => {
  const [loadedRowsMap, setLoadedRowsMap] = useState([]);
  const isRowLoaded = ({ index }) => !!loadedRowsMap[index];
  const loadMoreRows = ({ startIndex, stopIndex }) => {
    if (!loadMore) return;
    for (let i = startIndex; i <= stopIndex; i += 1) {
      loadedRowsMap[i] = 1;
    }
    setLoadedRowsMap(loadedRowsMap);
    // return loadMore('forward')
  };
  const onScroll = ({ scrollTop }) => {
    if (!loadMore || scrollTop !== 0) return;
    return loadMore('backwards');
  };
  return (
    <InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={loadMoreRows} rowCount={rowCount}>
      {({ onRowsRendered }) => (
        <VAutoSizer
          rowHeight={rowHeight}
          rowCount={rowCount}
          rowRenderer={rowRenderer}
          onRowsRendered={onRowsRendered}
          onScroll={onScroll}
          scrollTo={scrollTo}
        />
      )}
    </InfiniteLoader>
  );
};

VInfiniteLoader.propTypes = {
  loadMore: PropTypes.func,
};

VInfiniteLoader.defaultProps = {
  loadMore: undefined,
};

export default VInfiniteLoader;
