/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-use-before-define */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _remove from 'lodash/remove';

/**
 * Chat - Wrapper component for Chat. The needs to be placed around any other chat components.
 * This Chat component provides the chat context to all other components.
 *
 * The chat context provides the following props:
 *
 * - client (the client connection)
 * - channels (the list of channels)
 * - setActiveChannel (a function to set the currently active channel)
 * - activeChannel (the currently active channel)
 *
 */
const Chat = ({ client, render }) => {
  const [loadingChannels, setLoadingChannels] = useState(false);
  const [channels, setChannels] = useState([]);
  const [channelsPaginator, setChannelsPaginator] = useState(null);
  const [loadingChannel, setLoadingChannel] = useState(true);
  const [channel, setChannel] = useState(null);
  const [user, setUser] = useState(null);
  useEffect(() => {
    const initChat = async () => {
      console.log('Chat initChat', client.user);
      setUser(client.user);
    };
    const registerEvents = () => {
      console.log('Chat registerEvents');
      client.on('channelAdded', onChannelAdded);
      // client.on('channelInvited', onChannelInvited)
      // client.on('channelJoined', onChannelJoined)
      // client.on('channelLeft', onChannelLeft)
      client.on('channelRemoved', onChannelRemoved);
      // client.on('channelUpdated', onChannelUpdated)
      client.on('connectionError', onConnectionError);
      client.on('connectionStateChanged', onConnectionStateChanged);
      // client.on('memberJoined', onTypingEnded)
      // client.on('memberLeft', onTypingEnded)
      // client.on('memberUpdated', onTypingEnded)
      client.on('messageAdded', onMessageAdded);
      // client.on('messageRemoved', onTypingEnded)
      // client.on('messageUpdated', onTypingEnded)
      // client.on('pushNotification', onTypingEnded)
      // client.on('tokenAboutToExpire', onTypingEnded)
      // client.on('tokenExpired', onTypingEnded)
      // client.on('typingEnded', onTypingEnded)
      // client.on('typingStarted', onTypingEnded)
      // client.on('tokenExpired', onTypingEnded)
      // client.on('userSubscribed', onTypingEnded)
      // client.on('userUnsubscribed', onTypingEnded)
      // client.on('userUpdated', onTypingEnded)
    };
    registerEvents();
    initChat();
  }, []);

  const getSubscribedChannels = async () => {
    try {
      setLoadingChannels(true);
      const data = await client.getSubscribedChannels();
      setChannelsPaginator(data);
      setChannels(data.items);
      console.log('Chat getSubscribedChannels success:', data.items);
      setLoadingChannels(false);
    } catch (ex) {
      console.log('Chat getSubscribedChannels error', ex);
      setChannels([]);
      setLoadingChannels(false);
    }
  };

  const getChannelBySid = async (channelSid) => {
    try {
      setLoadingChannel(true);
      const data = await client.getChannelBySid(channelSid);
      setChannel(data);
      console.log('Chat getChannelBySid success:', data);
      setLoadingChannel(false);
    } catch (ex) {
      console.log('Chat getChannelBySid error:', ex);
      setChannel(null);
      setLoadingChannel(false);
    }
  };

  const onChannelAdded = async (chn) => {
    console.log('Chat onChannelAdded:', chn);
    setChannels((prevChannels) => [...prevChannels, chn]);
  };

  const onChannelRemoved = (chn) => {
    console.log('Chat onChannelRemoved:', chn);
    setChannels((prevChannels) => _remove(prevChannels, (current) => current.sid !== channel.sid));
  };

  const onConnectionError = (terminal, message, httpStatusCode, errorCode) => {
    console.log('Chat onConnectionError', terminal, message, httpStatusCode, errorCode);
  };

  const onConnectionStateChanged = (connectionState) => {
    console.log('Chat onConnectionStateChanged', connectionState);
  };

  const onMessageAdded = (message) => {
    console.log('Chat onMessageAdded', message);
  };

  console.log('Chat render:', client);
  return render({
    getSubscribedChannels,
    loadingChannels,
    channels,
    getChannelBySid,
    loadingChannel,
    channel,
    user,
  });
};

Chat.propTypes = {
  /**
   * A Twilio Client object.
   */
  client: PropTypes.object.isRequired,
  /**
   * Using the render props to make the chat more flexible
   */
  render: PropTypes.func.isRequired,
};

export default Chat;
