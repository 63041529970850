import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import MessageForm from './message-form';
/**
 * MessageModal - Render modal component, should be used together with the Message component
 *
 */
const MessageModal = ({ text, actionHandler, attributes, ...others }) => {
  const [modalState, setModalState] = useState(true);

  const messageFormHandler = (data) => {
    setModalState(false);
    actionHandler(data);
  };

  const handleCancel = () => {
    setModalState(false);
  };

  return (
    <Modal visible={modalState} footer={null} onCancel={handleCancel}>
      <MessageForm
        {...others}
        text={text}
        attributes={attributes}
        actionHandler={messageFormHandler}
      />
    </Modal>
  );
};

MessageModal.propTypes = {
  /** The Markdown or html source to parse */
  text: PropTypes.string.isRequired,
  /** Handler for actions */
  actionHandler: PropTypes.func.isRequired,
  /** Attributes */
  attributes: PropTypes.object.isRequired,
};

export default MessageModal;
