import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Menu, Empty, Skeleton } from 'antd';

const renderLoadingChannels = () => (
  <Menu>
    <Menu.Item>
      <Skeleton active avatar title paragraph={false} />
    </Menu.Item>
    <Menu.Item>
      <Skeleton active avatar title paragraph={false} />
    </Menu.Item>
    <Menu.Item>
      <Skeleton active avatar title paragraph={false} />
    </Menu.Item>
  </Menu>
);

const renderChannels = (channels, onClick, selectedChannel) =>
  channels.length ? (
    <Menu onClick={onClick} mode="vertical" selectedKeys={[selectedChannel]}>
      {channels.map((channel) => {
        const { sid, friendlyName } = channel;
        return <Menu.Item key={sid}>{friendlyName}</Menu.Item>;
      })}
    </Menu>
  ) : (
    <Empty />
  );

/**
 *  ChannelList - A preview list of channels, allowing you to select the channel you want to open
 */
const ChannelList = ({ loading, channels, onSelectChannel, defaultChannel }) => {
  const [selectedChannel, setSelectedChannel] = useState(undefined);
  useEffect(() => {
    if (!onSelectChannel || !defaultChannel) return;
    setSelectedChannel(defaultChannel);
    onSelectChannel({ key: defaultChannel });
  }, [defaultChannel]);

  const onClickHandler = (event) => {
    if (!onSelectChannel) return;
    setSelectedChannel(event.key);
    onSelectChannel(event);
  };

  if (loading) {
    return renderLoadingChannels();
  }

  return renderChannels(channels, onClickHandler, selectedChannel);
};

ChannelList.propTypes = {
  /**
   * List of all subscribed Channels
   */
  channels: PropTypes.arrayOf(PropTypes.object),
  /**
   * Fire when use select a channel the call back function with parameter is a DOM event
   */
  onSelectChannel: PropTypes.func,
  /**
   * Loading channels
   */
  loading: PropTypes.bool,
  /**
   * Set default selected channel
   */
  defaultChannel: PropTypes.string,
};

ChannelList.defaultProps = {
  channels: [],
  loading: false,
  onSelectChannel: undefined,
  selectedChannel: undefined,
};

export default ChannelList;
