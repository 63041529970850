import { createUseStyles } from 'react-jss';
import * as variables from '../styles/variables';

const useStyles = createUseStyles({
  message: {
    display: 'block',
    position: 'relative',
    '@global': {
      p: {
        margin: 0,
      },
    },
  },
  layoutStart: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  layoutEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  default: {
    background: '#ffffff',
    border: [1, 'solid', variables.borderColor],
    textAlign: 'left',
    marginLeft: 14,
    padding: [[5, 10]],
    maxWidth: 350,
    '&-top': {
      margin: [[20, 0, 1, 14]],
      borderRadius: [variables.borderRadius, variables.borderRadius, variables.borderRadius, 0],
    },
    '&-single': {
      margin: [[20, 0, 1, 14]],
      borderRadius: [variables.borderRadius, variables.borderRadius, variables.borderRadius, 0],
    },
    '&-middle': {
      margin: [[1, 0, 1, 14]],
      borderRadius: [2, variables.borderRadius, variables.borderRadius, 2],
    },
    '&-bottom': {
      margin: [[1, 0, 1, 14]],
      borderRadius: [0, variables.borderRadius, variables.borderRadius, variables.borderRadius],
    },
  },
  mine: {
    background: '#ebebeb',
    textAlign: 'left',
    marginRight: 14,
    padding: [[5, 10]],
    maxWidth: 350,
    '&-top': {
      margin: [[20, 14, 1, 0]],
      borderRadius: [variables.borderRadius, variables.borderRadius, 0, variables.borderRadius],
    },
    '&-single': {
      margin: [[20, 14, 1, 0]],
      borderRadius: [variables.borderRadius, variables.borderRadius, 0, variables.borderRadius],
    },
    '&-middle': {
      margin: [[1, 14, 1, 0]],
      borderRadius: [variables.borderRadius, 2, 2, variables.borderRadius],
    },
    '&-bottom': {
      margin: [[1, 14, 1, 0]],
      borderRadius: [variables.borderRadius, 2, variables.borderRadius, variables.borderRadius],
    },
  },
  avatar: {
    alignSelf: 'flex-end',
  },
  hideAvatar: {
    visibility: 'hidden',
  },
  '@global': {
    '.slick-arrow::before': {
      color: '#096dd9',
    },
  },
});

export default useStyles;
