import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Avatar, Tooltip } from 'antd';
import useStyles from '../message-styles';

const MessageAvatar = ({ name, groupStyle, align }) => {
  const classes = useStyles();
  const isShowAvatar = !['single', 'bottom'].includes(groupStyle);
  const info =
    name === 'system'
      ? {
          url:
            'https://storage.googleapis.com/download/storage/v1/b/nego-dev.appspot.com/o/products%2F1579251503520.png?generation=1579251503885163&alt=media',
          name: 'Sophia',
        }
      : {
          url: '',
          name: name,
        };
  return (
    <div
      className={classNames(classes.avatar, {
        [classes.hideAvatar]: isShowAvatar,
      })}
    >
      <Tooltip placement={align} title={info.name}>
        <Avatar src={info.url}>{info.name.substr(0, 1)}</Avatar>
      </Tooltip>
    </div>
  );
};

MessageAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  groupStyle: PropTypes.string.isRequired,
};

export default MessageAvatar;
