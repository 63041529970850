import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Row, Input, Button, Col } from 'antd';

const { TextArea } = Input;

/**
 * MessageInput - Input a new message
 */
const MessageInput = ({ handleSubmit, disabled }) => {
  const inputEl = useRef(null);
  const [value, setValue] = useState('');
  useEffect(() => {
    inputEl.current.focus();
  }, []);
  const onPressEnter = (event) => {
    // don't submit if the element has focus or the shift key is pressed
    if (event.shiftKey === true) {
      return;
    }
    handleSubmit({
      type: 'MessageInput',
      event,
      values: {
        text: value,
      },
    });
    setValue('');
    event.preventDefault();
  };

  const onSendClick = (event) => {
    handleSubmit({
      type: 'MessageInput',
      event,
      values: {
        text: value,
      },
    });
    setValue('');
  };

  const onChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Row type="flex" gutter={8} justify="space-between">
      <Col md={{ span: 22 }} xs={{ span: 24 }}>
        <TextArea
          ref={inputEl}
          placeholder="Type your message"
          autoSize={{ minRows: 1, maxRows: 5 }}
          onPressEnter={onPressEnter}
          onChange={onChange}
          value={value}
          disabled={disabled}
        />
      </Col>
      <Col md={{ span: 2 }} xs={{ span: 0 }}>
        <Button type="primary" block onClick={onSendClick} disabled={disabled}>
          Send
        </Button>
      </Col>
    </Row>
  );
};

MessageInput.propTypes = {
  /** Function that runs on submit the param recevied is a input value */
  handleSubmit: PropTypes.func.isRequired,
  /** Whether the input is disabled. */
  disabled: PropTypes.bool,
};

MessageInput.defaultProps = {
  disabled: false,
};

export default MessageInput;
