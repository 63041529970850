/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import VInfiniteLoader from './components/v-infinite-loader';
import VCellMeasurer from './components/v-cell-measurer';
import VCellMeasurerCache from './components/v-cell-measurer-cache';
import VLoading from './components/v-loading';
import Message from '../message';

const useStyles = createUseStyles({
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const cache = new VCellMeasurerCache({
  defaultHeight: 35,
  minHeight: 35,
});

const getGroupStyles = (messages) => {
  const { length } = messages;
  const messageGroupStyles = {};
  for (let i = 0; i < length; i += 1) {
    const previousMessage = messages[i - 1];
    const message = messages[i];
    const nextMessage = messages[i + 1];
    let groupStyles;
    const isTopMessage = !previousMessage || message.author !== previousMessage.author;
    const isBottomMessage = !nextMessage || message.author !== nextMessage.author;
    if (isTopMessage) {
      groupStyles = 'top';
    }
    if (isBottomMessage) {
      if (isTopMessage) {
        groupStyles = 'single';
      } else {
        groupStyles = 'bottom';
      }
    }
    if (!isTopMessage && !isBottomMessage) {
      groupStyles = 'middle';
    }
    messageGroupStyles[message.sid] = groupStyles;
  }
  return messageGroupStyles;
};

const isMine = (message, me) => message.author === me;

const MessageList = ({ me, messages, onMessageSend, loadMore }) => {
  const classes = useStyles();
  const groupStyles = getGroupStyles(messages);

  const rowRenderer = ({ index, key, parent, style }) => {
    const message = messages[index];
    const groupStyle = groupStyles[message.sid];
    const render = (measure) => {
      if (message === 'loading') {
        return (
          <div className={classes.center} style={style}>
            <VLoading measure={measure} />
          </div>
        );
      }
      const isRenderInteractionUI = index >= messages.length - 1 && !me.includes('admin-');
      return (
        <div style={style}>
          <Message
            isMine={isMine(message, me)}
            message={message}
            groupStyle={groupStyle}
            onMessageEvent={onMessageSend}
            isRenderInteractionUI={isRenderInteractionUI}
            onComplete={() => {
              if (!measure) {
                return;
              }
              measure();
            }}
          />
        </div>
      );
    };
    return (
      <VCellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
        render={render}
      />
    );
  };

  return (
    <VInfiniteLoader
      rowHeight={cache.rowHeight}
      rowRenderer={rowRenderer}
      rowCount={messages.length}
      loadMore={loadMore}
      scrollTo={messages.length === 0 ? 0 : messages.length - 1}
    />
  );
};

MessageList.propTypes = {
  /** The user friendly name */
  me: PropTypes.string.isRequired,
  /** The list of message in a Channel. */
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Fires when the user types a message and clicks the Send button or presses Enter. */
  onMessageSend: PropTypes.func.isRequired,
  /** Fires when the user scroll up or down */
  loadMore: PropTypes.func.isRequired,
};

export default MessageList;
