import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';
import { List } from 'react-virtualized';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  emptyContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const VList = ({
  width,
  height,
  rowCount,
  rowHeight,
  rowRenderer,
  onRowsRendered,
  onScroll,
  scrollTo,
}) => {
  const classes = useStyles();
  const [scrollToIndex, setScrollToIndex] = useState(undefined);

  useEffect(() => {
    setScrollToIndex(scrollTo);
  }, [scrollTo]);

  const noRowsRenderer = () => (
    <div className={classes.emptyContainer}>
      <Empty />
    </div>
  );

  const handleScroll = (data) => {
    setScrollToIndex(undefined);
    onScroll(data);
  };
  return (
    <List
      width={width}
      height={height}
      rowCount={rowCount}
      rowHeight={rowHeight}
      rowRenderer={rowRenderer}
      noRowsRenderer={noRowsRenderer}
      onRowsRendered={onRowsRendered}
      onScroll={handleScroll}
      scrollToIndex={scrollToIndex}
      scrollToAlignment="center"
      overscanRowCount={10}
    />
  );
};

VList.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]).isRequired,
  rowRenderer: PropTypes.func.isRequired,
  onRowsRendered: PropTypes.func.isRequired,
  onScroll: PropTypes.func.isRequired,
  scrollTo: PropTypes.number.isRequired,
};

export default VList;
