/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MessageWrapper from './components/message-wrapper';
import MessageAvatar from './components/message-avatar';
import useStyles from './message-styles';

/**
 * Message - A high level component which implements all the logic required for a message
 */
const Message = ({
  isMine,
  message,
  groupStyle,
  onMessageEvent,
  isRenderInteractionUI,
  onComplete,
}) => {
  useEffect(() => {
    if (!onComplete) {
      return;
    }
    onComplete();
  }, [isRenderInteractionUI]);

  const classes = useStyles();
  const { author } = message;
  return (
    <div className={classNames(classes.message, classes[groupStyle])}>
      {isMine ? (
        <div className={classes.layoutEnd}>
          <MessageWrapper
            groupStyle={groupStyle}
            isMine
            isRenderInteractionUI={isRenderInteractionUI}
            onMessageEvent={onMessageEvent}
            onComplete={onComplete}
            message={message}
          />
          <MessageAvatar groupStyle={groupStyle} name={author} align="topRight" />
        </div>
      ) : (
        <div className={classes.layoutStart}>
          <MessageAvatar groupStyle={groupStyle} name={author} align="topLeft" />
          <MessageWrapper
            groupStyle={groupStyle}
            isMine={false}
            isRenderInteractionUI={isRenderInteractionUI}
            onMessageEvent={onMessageEvent}
            onComplete={onComplete}
            message={message}
          />
        </div>
      )}
    </div>
  );
};
Message.propTypes = {
  /** The message object */
  message: PropTypes.object.isRequired,
  /** Indicate the message created by the user */
  isMine: PropTypes.bool.isRequired,
  /** A style to apply to this message. ie. top, bottom, single */
  groupStyle: PropTypes.string.isRequired,
  /** Fires when the user clicks a quick action button */
  onMessageEvent: PropTypes.func.isRequired,
  /** Is render interaction UI */
  isRenderInteractionUI: PropTypes.bool.isRequired,
  /** onComplete should be called when cell content is ready to be measured */
  onComplete: PropTypes.func.isRequired,
};

export default Message;
