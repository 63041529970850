/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { CellMeasurer } from 'react-virtualized';

const VCellMeasurer = ({ render, cache, key, parent, rowIndex, children }) => (
  <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={rowIndex}>
    {({ measure }) => render(measure)}
  </CellMeasurer>
);

export default VCellMeasurer;
