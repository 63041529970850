/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Spin, Icon } from 'antd';

const VLoading = ({ measure }) => {
  useEffect(() => {
    if (!measure) {
      return;
    }
    measure();
  }, []);
  const indicator = <Icon type="loading" spin />;
  return <Spin indicator={indicator} />;
};

export default VLoading;
