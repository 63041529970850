(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("antd"), require("bignumber.js"), require("classnames"), require("lodash/isEmpty"), require("lodash/isObject"), require("lodash/remove"), require("prop-types"), require("react"), require("react-jss"), require("react-markdown/with-html"), require("react-slick"), require("react-virtualized"), require("remark-gemoji-to-emoji"), require("slick-carousel/slick/slick-theme.css"), require("slick-carousel/slick/slick.css"));
	else if(typeof define === 'function' && define.amd)
		define(["antd", "bignumber.js", "classnames", "lodash/isEmpty", "lodash/isObject", "lodash/remove", "prop-types", "react", "react-jss", "react-markdown/with-html", "react-slick", "react-virtualized", "remark-gemoji-to-emoji", "slick-carousel/slick/slick-theme.css", "slick-carousel/slick/slick.css"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("antd"), require("bignumber.js"), require("classnames"), require("lodash/isEmpty"), require("lodash/isObject"), require("lodash/remove"), require("prop-types"), require("react"), require("react-jss"), require("react-markdown/with-html"), require("react-slick"), require("react-virtualized"), require("remark-gemoji-to-emoji"), require("slick-carousel/slick/slick-theme.css"), require("slick-carousel/slick/slick.css")) : factory(root["antd"], root["bignumber.js"], root["classnames"], root["lodash/isEmpty"], root["lodash/isObject"], root["lodash/remove"], root["prop-types"], root["react"], root["react-jss"], root["react-markdown/with-html"], root["react-slick"], root["react-virtualized"], root["remark-gemoji-to-emoji"], root["slick-carousel/slick/slick-theme.css"], root["slick-carousel/slick/slick.css"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE_antd__, __WEBPACK_EXTERNAL_MODULE_bignumber_js__, __WEBPACK_EXTERNAL_MODULE_classnames__, __WEBPACK_EXTERNAL_MODULE_lodash_isEmpty__, __WEBPACK_EXTERNAL_MODULE_lodash_isObject__, __WEBPACK_EXTERNAL_MODULE_lodash_remove__, __WEBPACK_EXTERNAL_MODULE_prop_types__, __WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_react_jss__, __WEBPACK_EXTERNAL_MODULE_react_markdown_with_html__, __WEBPACK_EXTERNAL_MODULE_react_slick__, __WEBPACK_EXTERNAL_MODULE_react_virtualized__, __WEBPACK_EXTERNAL_MODULE_remark_gemoji_to_emoji__, __WEBPACK_EXTERNAL_MODULE_slick_carousel_slick_slick_theme_css__, __WEBPACK_EXTERNAL_MODULE_slick_carousel_slick_slick_css__) {
return 