import React from 'react';
import PropTypes from 'prop-types';
import MarkdownText from './components/markdown-text';

/**
 * MessageSimple - Render component, should be used together with the Message component
 *
 */
const MessageSimple = ({ text }) => <MarkdownText text={text} />;

MessageSimple.propTypes = {
  /**
   * The Markdown or html source to parse
   */
  text: PropTypes.string.isRequired,
};

export default MessageSimple;
