/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { AutoSizer } from 'react-virtualized';
import VList from './v-list';

const VAutoSizer = (props) => (
  <AutoSizer>{({ height, width }) => <VList height={height} width={width} {...props} />}</AutoSizer>
);

export default VAutoSizer;
