/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MessageSimple from '../message-simple';
import MessageActions from '../message-actions';
import MessageForm from '../message-form';
import MessageModal from '../message-modal';
import MessageCarousel from '../message-carousel';
import TypingIndicator from './typing-indicator';
import useStyles from '../message-styles';

const MessageWrapper = ({
  groupStyle,
  isMine,
  onMessageEvent,
  onComplete,
  isRenderInteractionUI,
  message,
}) => {
  const classes = useStyles();
  const { attributes, body, timestamp } = message;
  let parsedBody;
  try {
    parsedBody = JSON.parse(body);
  } catch {
    parsedBody = {
      type: 'default',
    };
  }
  let MessageComp = null;
  const { type, ...others } = parsedBody;
  if (!isRenderInteractionUI) {
    MessageComp = <MessageSimple {...others} />;
  } else {
    switch (type) {
      case 'simple':
        MessageComp = <MessageSimple {...others} />;
        break;
      case 'action':
        MessageComp = (
          <MessageActions {...others} attributes={attributes} actionHandler={onMessageEvent} />
        );
        break;
      case 'form':
        MessageComp = (
          <MessageForm {...others} attributes={attributes} actionHandler={onMessageEvent} />
        );
        break;
      case 'modal':
        MessageComp = (
          <MessageModal {...others} attributes={attributes} actionHandler={onMessageEvent} />
        );
        break;
      case 'carousel':
        MessageComp = (
          <MessageCarousel {...others} attributes={attributes} actionHandler={onMessageEvent} />
        );
        break;
      default:
        MessageComp = <div>invalid</div>;
        break;
    }
  }
  const styles = isMine
    ? [classes.mine, `${classes.mine}-${groupStyle}`]
    : [classes.default, `${classes.default}-${groupStyle}`];

  MessageComp =
    !isMine && isRenderInteractionUI ? (
      <TypingIndicator
        Component={MessageComp}
        delay={3000}
        dateCreated={timestamp}
        onComplete={onComplete}
      />
    ) : (
      MessageComp
    );
  return <div className={classNames(styles)}>{MessageComp}</div>;
};

MessageWrapper.propTypes = {
  /** A style to apply to this message. ie. top, bottom, single */
  groupStyle: PropTypes.string.isRequired,
  /** Indicate the message created by the user */
  isMine: PropTypes.bool.isRequired,
  /** Is render interaction UI */
  isRenderInteractionUI: PropTypes.bool.isRequired,
  /** Fires when the user clicks a quick action button */
  onMessageEvent: PropTypes.func.isRequired,
  /** onComplete should be called when cell content is ready to be measured */
  onComplete: PropTypes.func.isRequired,
  /** The message object */
  message: PropTypes.object.isRequired,
};

export default MessageWrapper;
