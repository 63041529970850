import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const TypingIndicator = ({ Component, delay, dateCreated, onComplete }) => {
  const [typing, setTyping] = useState(false);
  useEffect(() => {
    if (!dateCreated) {
      return;
    }
    const delta = Date.now() - dateCreated.getTime();
    if (delta < delay) {
      setTyping(true);
      setTimeout(() => {
        setTyping(false);
        onComplete();
      }, delay);
    }
  }, []);
  return typing ? <div>typing...</div> : Component;
};

TypingIndicator.propTypes = {
  /** The component will be redered after a delay time */
  Component: PropTypes.element.isRequired,
  /** The number of time in miliseconds to show the typing... */
  delay: PropTypes.number.isRequired,
  /** This is the date that message created */
  dateCreated: PropTypes.instanceOf(Date).isRequired,
  /** This function will call after the delay time for animation complete */
  onComplete: PropTypes.func.isRequired,
};

export default TypingIndicator;
